import "./App.css";
import Search from "./components/search/Search";
import Forecast from "./components/forecast/forecast";
import CurrentWeather from "./components/current-weather/currentWeather";
import { WEATHER_API_KEY, WEATHER_API_URL } from "./components/search/api";
import { useState } from "react";

function App() {
  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecastWeather, setForecastWeather] = useState(null);

  const handleOnSearchChange = (searchData) => {
    const [lat, lon] = searchData.value.split(" ");

    const currentWeatherFetch = fetch(
      `${WEATHER_API_URL}/weather?id=id=524901&lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`
    );

    const forecastWeatherFetch = fetch(
      `${WEATHER_API_URL}/forecast?id=id=524901&lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`
    );

    Promise.all([currentWeatherFetch, forecastWeatherFetch])
      .then(async (response) => {
        const weatherResponse = await response[0].json();
        const forecastResponse = await response[1].json();

        setCurrentWeather({ city: searchData.label, ...weatherResponse });
        setForecastWeather({ city: searchData.label, ...forecastResponse });
      })
      .catch((err) => console.log(err));
  };

  console.log(currentWeather);
  // console.log(forecastWeather);

  const appBg = {
    // backgroundImage: `url(${
    //   process.env.PUBLIC_URL + "/images/weather-bg.jpg"
    // })`,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${
      process.env.PUBLIC_URL + "/images/weather-bg.jpg"
    })`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    // transition: "opacity 0.2s ease-in-out",
  };

  return (
    <div className="container" style={currentWeather && appBg}>
      <Search onSearchChange={handleOnSearchChange} />
      <div className="weather-container">
        {currentWeather && <CurrentWeather data={currentWeather} />}
        {forecastWeather && <Forecast data={forecastWeather} />}
      </div>
    </div>
  );
}

export default App;
