import "./currentWeather.css";

const CurrentWeather = ({ data }) => {
  return (
    <div
      className="weather"
      style={
        data.main.temp >= 30
          ? { backgroundColor: "#9c7213" }
          : {} && data.main.temp <= 15
          ? { backgroundColor: "blue" }
          : {}
      }
    >
      <p className="location">{data.city}</p>
      <p className="weather-description">{data.weather[0].description}</p>
      <div className="top">
        <p className="temperature">{Math.round(data.main.temp)}°C</p>

        <img
          src={`icons/${data.weather[0].icon}.png`}
          alt="weather-image"
          className="weather-image"
        />
      </div>
      <div className="bottom">
        <table>
          <tr>
            <td>
              <p>Feels Like</p>
            </td>
            <td>
              <p>{Math.round(data.main.feels_like)}°C</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>Humidity</p>
            </td>
            <td>
              <p>{Math.round(data.main.humidity)}</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>Wind</p>
            </td>
            <td>
              <p>{data.wind.speed} m/s</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>Pressure</p>
            </td>
            <td>
              <p>{data.main.pressure}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default CurrentWeather;
