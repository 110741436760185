export const geoApiOptions = {
	method: 'GET',
	headers: {
		'X-RapidAPI-Key': '55e1eb62afmsh4a76b00e2f1426cp13bad4jsn3cf60d4aecf0',
		'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com'
	}
};

export const GEO_API_URL = "https://wft-geo-db.p.rapidapi.com/v1/geo";

export const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5";
export const WEATHER_API_KEY = "5dbe6c6221000986b1bda696a345de5a";